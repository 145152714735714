<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h3 class="mb-0">
          {{ $t('builder.preview') }}
        </h3>
      </div>
      <b-button-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="!typePreview ? 'primary' : 'outline-primary'"
          @click="changeTypePreview(0)"
        >
          <feather-icon
            icon="MonitorIcon"
          />
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="typePreview ? 'primary' : 'outline-primary'"
          @click="changeTypePreview(1)"
        >
          <feather-icon
            icon="SmartphoneIcon"
          />
        </b-button>
      </b-button-group>
    </div>
    <div
      class="preview-container"
      :class="{
        'desktop': typePreview === 0,
        'mobile': typePreview !== 0
      }"
    >
      <div
        id="preview-params"
        ref="preview"
      />
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'WidgetBuilderPreview',
  components: {
    BButton,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      typePreview: 0,
      titles: ['The Mysterious Tale of the Disastrous Summer Vacation', 'The Exploding Labyrinth of Dark Deceit', 'The Spectacle of Golden Possibilities', 'The Curse of the Blinking Shadow', 'The Mystery of the Shifting Sands', 'The Lost Kingdom of the Everlasting Wind', 'The Quest for the Silver Fire', 'The Prophecy of the Flaming Fog', 'The Revenge of the Thundering Flame', 'The Legend of the Eerie Stream', 'The Fear of the Forbidden Garden', 'The Search for the Celestial Flame', 'The Adventure of the Crimson Moon', 'The Secret of the Mystic Maze'],
      teasers: [],
      teasersLimit: 14,
      tableArray: [],
    }
  },
  computed: {
    teasersTotal() {
      return this.teasers.length
    },
    teasersPlaces() {
      return this.blockData.rows * this.blockData.cols
    },
    teaserData() {
      let teasers = []
      if (this.teasersTotal < this.teasersPlaces && this.teasersLimit > 0) {
        teasers = this.teasers.slice(0, this.teasersPlaces - this.teasersTotal)
      }
      return teasers
    },
    getImgHeight() {
      return !this.blockData.img_wide ? this.blockData.img_width : Math.round(this.blockData.img_width / 1.76)
    },
    minTdWidth() {
      let result = 0
      if (this.blockData.desc_position) {
        result = parseInt(this.blockData.img_width, 10) + 2 * (parseInt(this.blockData.img_border_size, 10) + parseInt(this.blockData.td_border_size, 10) + this.blockData.table_padding)
      } else {
        result = this.blockData.table_width && !this.blockData.table_width_units ? (this.blockData.table_width / this.blockData.cols) * 1.1 : 3 * this.blockData.img_width
      }
      return result
    },
  },
  watch: {
    blockData: {
      handler() {
        this.tableArray = []
        this.$nextTick().then(() => {
          this.setRandomSource()
          this.htmlTemplate()
        })
      },
      deep: true,
    },
    typePreview() {
      this.resizeIframe()
      this.$nextTick().then(() => {
        const evt = window.document.createEvent('UIEvents')
        evt.initUIEvent('resize', true, false, window, 0)
        window.dispatchEvent(evt)
      })
    },
  },
  created() {
    this.setDefaultTeasersData()
  },
  mounted() {
    this.htmlTemplate()
    window.addEventListener('resize', this.resizeIframe)
  },
  beforeUnmount() {
    window.addEventListener('resize', this.resizeIframe)
  },
  methods: {
    setDefaultTeasersData() {
      const min = 0
      const max = this.teasersLimit - 1
      for (let i = min; i < this.teasersLimit; i += 1) {
        const randomIndex = Math.floor(Math.random() * max) + min
        this.teasers.push({
          img: [`https://phoenix-widget.com/wp-content/uploads/2022/11/img${randomIndex}-s.jpg`, `https://phoenix-widget.com/wp-content/uploads/2022/11/img${randomIndex}-r.jpg`],
          desc: this.titles[randomIndex],
          url: '#',
        })
      }
      this.setRandomSource()
    },
    setRandomSource() {
      for (let i = 0; i < this.teasersPlaces; i += 1) {
        this.teasers[i].source = ''
      }
      if (this.blockData.block_source_title) {
        const totalSorces = this.blockData.rows
        for (let y = 0; y < totalSorces; y += 1) {
          const randomTeaserIndex = Math.floor(Math.random() * (this.teasersPlaces - 1))
          this.teasers[randomTeaserIndex].source = this.blockData.block_source_title
        }
      }
    },
    changeTypePreview(type) {
      this.typePreview = type
    },
    htmlTemplate() {
      if (Object.keys(this.blockData).length) {
        this.$refs.preview.innerHTML = ''
        const previewIframe = document.createElement('iframe')
        previewIframe.style.cssText = 'border: 0px !important; width: 100%;margin: 0px !important; padding: 0px !important;'
        previewIframe.onload = () => {
          const doc = previewIframe.contentDocument
          doc.head.innerHTML = `<style rel="stylesheet">${this.blockStyles()}</style>`
          doc.body.innerHTML = `<div>${this.conditionStyle()}${this.createTable()}</div>`
        }
        this.$refs.preview.appendChild(previewIframe)
      }
      this.resizeIframe()
    },
    createTable() {
      let tableHtml = ''
      const tdWidth = Math.round(100 / this.blockData.cols, 4)

      for (let t = 0, i = 0; i < this.blockData.rows; i += 1) {
        if (t <= (this.teasersTotal - 1)) {
          tableHtml += '<tr>'
          for (let f = 0; f < this.blockData.cols; f += 1) {
            tableHtml += `<td class="pnx-td idx-${t}" style="width: ${tdWidth}%">${this.teaserTemplate(this.teasers[t])}</td>`
            t += 1
          }
          tableHtml += '</tr>'
        }
      }

      return `<div style="position:relative !important;overflow:hidden !important;" class="pnx-div">
        <table style="margin:0 -${this.blockData.table_cellspacing_h}px;width:calc(100% + ${2 * this.blockData.table_cellspacing_h}px)" border='0' cellpadding='1' cellspacing='10' class="pnx-table">
        <tbody>${tableHtml}</tbody></table><div class="pnx-logo"></div></div>`
    },
    teaserTemplate(teaser) {
      if (teaser) {
        const tImg = teaser.img[this.blockData.img_wide]
        return `<a href="javascript:void(0);" class="pnx-cont">
          <div style="display:block !important;">
            <div class="pnx-imgbox"><img src="${tImg}" class="pnx-img"></div>
          </div>
          <div class="pnx-desc"><span>${teaser.desc}</span></div>
          ${teaser.source ? `<div class="pnx-source">${teaser.source}</div>` : ''}
          </a>`
      }

      return ''
    },
    blockStyles() {
      const baseStyles = `
      body{overflow: hidden !important;}
      body > div {display:flex}
      .pnx-table{table-layout:auto;-webkit-hyphens:auto;-moz-hyphens:auto;-ms-hyphens:auto;hyphens:auto;word-break:break-word;border-collapse:separate;border:0;margin:0;width:${this.blockData.table_width}${this.blockData.table_width_units ? '%' : 'px'};${this.blockData.fixed && this.blockData.table_height ? `height: ${this.blockData.table_height}${this.blockData.table_height_units ? '%' : 'px'}` : ''};border-spacing: ${this.blockData.table_cellspacing_h}px ${this.blockData.table_cellspacing_v}px!important;background-color:${this.blockData.table_bg_color}!important;padding: ${this.blockData.table_padding}px;}
      .pnx-td{text-align: center !important;padding: ${(this.blockData.td_padding || 0)}px;background-color:${this.blockData.td_bg_color} !important;vertical-align:top;border: ${this.blockData.td_border_size}px ${this.blockData.td_border_type} ${this.blockData.td_border_color};border-radius: ${this.blockData.td_border_radius}px;line-height:0;}
      .pnx-cont{text-decoration:none;display:block !important;-ms-word-break:break-all;word-break:break-all;word-break:break-word;-webkit-hyphens:auto;-moz-hyphens:auto;hyphens:auto;margin:auto;padding:0;text-align: ${this.blockData.text_align};position:relative;max-width: ${Math.round(this.blockData.img_width * (this.blockData.img_wide === 1 ? 1.33 : 1))}px;}
      .pnx-img{max-width: 100%;max-height: 100%;border: 0;margin: auto;padding: 0;${this.blockData.desc_position === 0 ? '' : 'position: absolute;'}top: 0;right: 0;bottom: 0;left: 0;width: 100%;height: auto;border-radius: ${this.blockData.img_border_radius}px;}
      .pnx-imgbox{float:none;height:0;display:block;position:relative;box-sizing: content-box;border: ${this.blockData.img_border_size}px ${this.blockData.img_border_type} ${this.blockData.img_border_color};padding: ${(this.blockData.img_wide === 1 ? 56.58 : 100)}% 0 0 0;margin: ${this.blockData.text_align === 'center' ? `0 auto  ${this.blockData.img_text_indent}px auto` : `0 0 ${this.blockData.img_text_indent}px 0`};border-radius: ${this.blockData.img_border_radius === 0 ? `${this.blockData.img_border_radius}` : `${parseInt(this.blockData.img_border_radius, 10) + parseInt(this.blockData.img_border_size, 10)}`}px;}
      .pnx-desc{${this.blockData.desc_font_size ? `font-size: ${this.blockData.desc_font_size}px;` : ''} font-family: ${this.blockData.desc_font && this.blockData.desc_font !== '' ? this.blockData.desc_font : 'inherit'};color:${this.blockData.desc_font_color};text-decoration: ${this.blockData.desc_text_decorat === 1 ? 'underline' : 'none'};font-weight:${this.blockData.desc_font_weight === 'bold' ? 'bold' : 'normal'};float:none;margin:0;padding:${this.blockData.text_align !== 'center' ? `0 ${this.blockData.desc_text_padding}px` : '0'};line-height:normal;display:block;}
      .pnx-div{width: ${this.blockData.table_width}${this.blockData.table_width_units ? '%' : 'px'};margin: auto;box-sizing: border-box; padding: 0;}      
      .pnx-logo{display: block;width:56px;height: 14px;overflow: hidden;position: absolute;box-sizing: content-box;right: 0;top: 0;margin: 0;border: 0;padding: 1px;transition: 0.3s;background: url(https://phoenix-widget.com/wp-content/themes/phoenix/assets/img/logo.png) 2px 2px no-repeat #fff;background-size: auto 80%;border-radius: 0 0 0 5px;}
      .pnx-logo:hover{display: block;}
      .pnx-source{color: #a3a3aa;font-family: sans-serif;font-size: 13px;height: 14px;line-height: 16px;text-align: left;display: block;margin: 0;border: 0;padding: 4px 0;border-radius: 0;}
      `
      return baseStyles
    },
    conditionStyle() {
      return `<style>${this.blockData.desc_position === 0 ? `.pnx-imgbox{float:left;padding: 0;margin: 0 ${this.blockData.img_text_indent}px 0 0;width: ${this.blockData.img_width}px;height: ${this.getImgHeight}px;max-width:auto;}.pnx-cont {text-align:left;max-width: initial;}` : ''}
        ${this.blockData.fixed === 1 ? `.pnx-div{width: ${this.blockData.table_width}px;${this.blockData.table_height ? `height: ${this.blockData.table_height}${this.blockData.table_height_units ? '%' : 'px'};` : ''}}` : ''}
        ${this.blockData.style ? this.blockData.style : ''}
        ${this.blockData.block_img_max_height && parseInt(this.blockData.block_img_max_height, 10) > 0 ? `.pnx-imgbox{height: ${this.blockData.block_img_max_height}px;padding: 0;}.pnx-imgbox img {height: 100%;}` : ''}</style>`
    },
    iframeHeight(height) {
      return this.blockData.type ? 290 : height
    },
    resizeIframe() {
      const iframeElement = document.querySelector('#preview-params > iframe')
      if (iframeElement) {
        const iframeDoc = iframeElement.contentDocument
        const html = iframeDoc.documentElement
        if (html.offsetHeight > 30) {
          this.adaptations(iframeDoc)
          setTimeout(() => {
            const height = iframeDoc.querySelector('body > div').offsetHeight + 26
            iframeElement.style.height = `${this.iframeHeight(height)}px`
          }, 100)
        } else {
          setTimeout(() => {
            this.resizeIframe()
          }, 50)
        }
      }
    },
    adaptations(iframeDoc) {
      if (iframeDoc) {
        const table = iframeDoc.querySelector('table')
        const { clientWidth, clientHeight } = iframeDoc.documentElement
        const tableRows = this.blockData.rows
        const tableCols = this.blockData.cols
        const { minTdWidth } = this

        if (table && table.rows !== null) {
          if (this.tableArray.length === 0) {
            for (let r = 0; r < table.rows.length; r += 1) {
              for (let c = 0; c < table.rows[r].cells.length; c += 1) {
                this.tableArray.push(table.rows[r].cells[c])
              }
            }
          }

          if (this.blockData.adaptation_type === 1) {
            const tableCellspacingH = this.blockData.table_cellspacing_h
            if (clientWidth && clientHeight) {
              if (tableCols > 1 && minTdWidth) {
                let e = Math.min(Math.ceil((clientWidth - tableCellspacingH) / (minTdWidth + tableCellspacingH)), tableCols)
                if (e > 1) {
                  const v = (clientWidth - tableCellspacingH) / e
                  const b = (clientWidth - tableCellspacingH) / (e - 1)
                  const g = v - (minTdWidth + tableCellspacingH)
                  const w = b - (minTdWidth + tableCellspacingH)
                  if (w * w < g * g) {
                    e -= 1
                  }
                }

                for (let r = 0; r < table.rows.length; r += 1) {
                  for (let n = tableCols - 1; n >= 0; n -= 1) {
                    const p = table.rows[r].cells[n].style
                    const y = n + 1 > e
                    p.display = y ? 'none' : 'table-cell'
                    p.width = `${Math.floor(100 / e)}%`
                  }
                }
              }
            }
          } else if (this.blockData.adaptation_type === 2) {
            if (tableCols > 1) {
              const calcCols = Math.min(tableCols, Math.max(1, Math.floor(clientWidth / minTdWidth / 0.8)))
              const calcRows = Math.ceil((tableRows * tableCols) / calcCols)
              for (let i = 0; i < this.tableArray.length; i += 1) {
                const colIdx = Math.floor(i / calcRows)
                const rowIdx = i + 1 - colIdx * calcRows - 1

                if (rowIdx === i && this.tableArray[i]) {
                  table.insertRow(rowIdx)
                }

                if (table.rows[rowIdx]) {
                  table.rows[rowIdx].appendChild(this.tableArray[i])
                }
              }
              while (table.rows.length > calcRows) {
                table.deleteRow(table.rows.length - 1)
              }
            }
          }
        }
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.preview-container
  margin: auto

  &.desktop
    width: 100%

  &.mobile
    width: 360px

#preview-params
  display: block !important
  width: 100% !important
  margin: 0px 0px 0px 0px !important
</style>
